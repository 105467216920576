<template>
        <div id="game_events" v-if="events_loaded">
          <button
            v-for="event in events"
            class="event"
            :key="event.id"
            @click="setCurrentEvent(event)"
            :class="checkSelected(event.id)"
          >{{event.event_title}}<br>
            <vue-countdown :time="event.time_to*1000" v-slot="{totalHours, totalMinutes, minutes, seconds}" @end="countdownEnd()" @progress="wait3sec">
              {{formatCounter(totalHours, totalMinutes, minutes, seconds)}}
            </vue-countdown>
          </button>
      </div>
      <div v-if="!events_loaded">
        <h2 class="no_events">{{game_name}} {{$t('gametemplate.inactive')}}</h2>
      </div>
      <div id="game_container">
        <div class="overbets" v-if="betsAlert && view_receipt_bets.length === 0">
          <span class="whiteText">{{t('cashier.betsOut')}}</span><br><span class="redText">{{minEventStartSec}}</span>
        </div>
        <component :is="game" @getBet="setCurrentBet($event)" @splitBets="splitBets($event)" ref="game_component" :event="current_event" :public_event="public_event" :bet_value="bet_value" :game="game"></component>
      </div>

</template>

<script>

import GameService from '../services/game.service';
import cashier from '../services/cashier.service';
import {inject, ref, provide} from "vue";

export default {
  name: "GameTemplate",
  data () {
    return {
      current_bet: [],
      events: [],
      current_event: '',
      public_event: '',
      events_loaded: true,
      minEventStartSec: 60,
      betsAlert: false
    }
  },
  setup()
  {
    const set_result = inject('set_result');
    const isUpBalance = inject('isUpBalance');
    const route = inject('route');
    const setIsLoading = inject('setIsLoading');
    const t = inject('$t')
    const view_receipt_bets = inject('view_receipt_bets');
    const race6_bets_obj = ref([]);
    const race8_bets_obj = ref([]);
    const fight_bets_obj = ref([]);

    provide('race6_bets_obj', race6_bets_obj);
    provide('race8_bets_obj', race8_bets_obj);
    provide('fight_bets_obj', fight_bets_obj);

    return {
      set_result,
      isUpBalance,
      route,
      setIsLoading,
      t,
      view_receipt_bets,
      race6_bets_obj,
      race8_bets_obj,
      fight_bets_obj
    }
  },
  props: ['game', 'game_id', 'game_name', 'bet_value'],
  emits: ['setCurrentBet', 'setCurrentEvent', 'countdownEnd', 'clearBets', 'splitBets'],
  methods: {
    setCurrentBet(bet) {
      this.$emit('setCurrentBet', bet);
      this.current_bet = bet;
    },
    getEvents(game)
    {
      GameService.getEvents(game).then(
          (response) => {
            if(response.data) {
              this.events = response.data;

              if(response.data.length > 0)
              {
                this.events_loaded = true;
              }
              else
              {
                this.events_loaded = false;
              }
            }
            else
            {
              this.events = [];
              this.events_loaded = false;
            }

            if(this.events.length > 0 && this.current_event === '')
            {
              this.current_event = this.events[0].id;
              this.public_event = this.events[0].public_id;
              if(this.events[0].time_to != 0)
              {
                this.minEventStartSec = this.events[0].time_to;
              }
            }

            this.setIsLoading(false);
          },
          (error) => {
            this.set_result(error.response.status);
            this.setIsLoading(false);
          }
      );
    },
    setCurrentEvent(event)
    {
      this.$emit('setCurrentEvent', event);
      this.current_event = event.id;
      this.public_event = event.public_id;
    },
    clear_bet()
    {
      this.$refs.game_component.clear_bet();
      this.current_bet = [];
    },
    formatCounter(totalHours, totalMinutes, minutes, seconds)
    {
      let format = totalMinutes+' min '+seconds+' sec';

      if(totalMinutes > 59)
      {
        format = totalHours+' hrs '+minutes+' min';
      }

      return format;
    },
    countdownEnd()
    {
      this.current_event = '';
      this.getEvents(this.game_id);
      this.$emit('countdownEnd');
    },
    splitBets()
    {
      this.$emit('splitBets');
    },
    checkSelected(id)
    {
      if(id === this.current_event)
      {
        return 'selected cur_selected';
      }

      for(let bet of this.current_bet)
      {
        if(id === bet.event)
        {
          return 'selected';
        }
      }

      for(let event of this.events)
      {
        if(event.id === id && event.is_active_bets === 'y')
        {
          return 'active_bets_event';
        }
      }
    },
    eventsPreload()
    {
      this.setIsLoading(true);
      this.getEvents(this.game_id);
    },
    wait3sec(data)
    {
      let sec = data.totalSeconds

      if(sec % 60 === 0)
      {

        this.minEventStartSec = sec
      }

      if(sec < this.minEventStartSec)
      {
        this.minEventStartSec = sec
      }

      let bet_event = false;

      for(let bet of this.current_bet)
      {
        if(bet.event == this.events[0].id)
        {
          bet_event = true;
        }
      }

      if(this.minEventStartSec <= 3 && (this.current_event == this.events[0].id || bet_event))
      {
        this.betsAlert = true;

        if(this.minEventStartSec === 1)
        {
          this.$emit('clearBets');
        }
      }
      else
      {
        this.betsAlert = false
      }
    },
    getRace6Bets()
    {
      cashier.getRace6Bets(this.current_event).then(
          (response) => {
            this.race6_bets_obj = response.data;
          }
      );
    },
    getRace8Bets()
    {
      cashier.getRace8Bets(this.current_event).then(
          (response) => {
            this.race8_bets_obj = response.data;
          }
      );
    },
    getFightBets()
    {
      cashier.getFightBets(this.current_event).then(
          (response) => {
            this.fight_bets_obj = response.data;
          }
      );
    },
    convert_coef(coef, type)
    {
      if (type == 'american')
      {
        if (coef <= 1)
        {
          return 0;
        }

        if (coef >= 2)
        {
          return Math.floor(((coef - 1) * 100));
        }
      }
      return  Math.floor((-100 / (coef - 1)));
    }
  },
  mounted() {
    this.eventsPreload();
  },
  watch: {
    isUpBalance: function () {
      if (this.route.name !== 'statistics') {
        this.getEvents(this.game_id);
      }
    },
    current_event: function () {
      if (this.route.name !== 'statistics' && this.game === 'Race6') {
        this.getRace6Bets();
      }
      if (this.route.name !== 'statistics' && this.game === 'Race8') {
        this.getRace8Bets();
      }
      if (this.route.name !== 'statistics' && this.game === 'Fight') {
        this.getFightBets();
      }
    }
  }
}
</script>

<style scoped>
  #game_events
  {
    overflow: auto;
    overflow-y: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .event
  {
    flex: none;
    margin-left: 10px;
    height: 75%;
    width: 10%;
    font-size: 65%;
  }
  .no_events
  {
    color: red;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .selected
  {
    background-color: #53ea93;
  }
  .cur_selected
  {
    border: 3px solid;
  }
  .active_bets_event
  {
    background-color: darkgrey;
  }
  .overbets
  {
    position: fixed;
    display: block;
    top: 4%;
    left: 0;
    z-index: 9999999;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
    background-color: #464d55;
    opacity: 0.9;
    text-align: center;

  }
  .whiteText
  {
    color: white;
    font-size: 40pt;
    font-weight: bold;
  }
  .redText
  {
    color: red;
    font-size: 40pt;
    font-weight: bold;
  }
</style>