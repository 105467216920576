<template>
  <div id="cashier_container">
    <div class="overlay" :class="modal"></div>
    <div :class="{modal_receipt: check_modal_rec_class(), modal: check_modal_class(), open: check_open_class()}">
        <div v-if="receipt_action != 'make'">{{ $t('cashier.securecode') }}<input v-model="secure_code">
          <button @click="verify">{{$t('cashier.button_ok')}}</button>
          <button @click="close_modal">{{ $t('cashier.button_close') }}</button>
        </div>
      <div v-if="receipt_action == 'make'">
        <div class="dvc" v-html="receipt_html"></div>
        <button class="bcm" @click="close_modal">{{$t('cashier.button_ok')}}</button>
      </div>
    </div>
    <div id="cashier">
      <div id="cashier_game">
        <div id="game_template">
          <GameTemplate
            v-if="selected_game !== ''"
            :game="selected_game"
            :game_name="selected_game_name"
            :game_id="selected_game_id"
            :bet_value="bet_value"
            @setCurrentBet="setCurrentBet($event)"
            @setCurrentEvent="setCurrentEvent($event)"
            @countdownEnd="countdownEnd($event)"
            @clearBets="clearBets($event)"
            @splitBets="splitBets($event)"
            ref="game_template"
          ></GameTemplate>
        </div>
        <div id="bets_preview" :class="{full_height: btp_full_screen}" v-if="selected_game !== ''">
          <div id="receipts" v-if="view_receipt_bets.length === 0">
            <div class="receipt_table_header">
              <table class="receipt_table_th">
                <tr>
                  <th><input v-model="find_receipt" @keyup="find_receipts()" v-on:input="ProceedEntry" @keyup.enter="tryOpenReceipt"></th>
                  <th></th>
                  <th></th>
                  <th>
                    <input type="checkbox" id="not_played" class="ar_filter" v-model="not_played_val"><label for="not_played">{{ $t('cashier.notplayed') }}</label>
                    <input type="checkbox" id="not_paid" class="ar_filter" v-model="not_paid_val"><label for="not_paid">{{ $t('cashier.notpaid') }}</label>
                  </th>
                  <th></th>
                  <th></th>
                  <th class="pseudo_scroll"></th>
                </tr>
                <tr>
                  <th>#</th>
                  <th>{{ $t('cashier.receiptdate') }}</th>
                  <th>{{ $t('cashier.game') }}</th>
                  <th>{{ $t('cashier.status') }}</th>
                  <th>{{ $t('cashier.betsvalue') }}</th>
                  <th>{{ $t('cashier.payout') }}</th>
                  <th></th>
                </tr>
              </table>
            </div>
            <div class="receipt_table_data">
              <table class="receipt_table">
                <tr v-for="receipt in receipts_comp" :key="receipt.id" @click="view_receipt(receipt)"  :class="view_receipt_tr(receipt.status)">
                  <td>#{{receipt.id}}</td>
                  <td>{{receipt.check_data_tz}}</td>
                  <td>{{receipt.game_name}}</td>
                  <td>{{$t('cashier.receipt_status_'+receipt.status)}}</td>
                  <td>{{receipt.sum_bets_formatted}}</td>
                  <td>{{receipt.sum_payout_formatted}}</td>
                </tr>
              </table>
            </div>
          </div>
          <div id="view_receipt" v-if="view_receipt_bets.length !== 0">
            <span class="btp_hint">
              {{$t('cashier.receipt_num')}}{{view_receipt_id}} |
              {{$t('statistics.detail_table_Receipt login')}}: {{view_receipt_login}}
              | {{$t('statistics.detail_table_Receipt date')}}: {{view_receipt_date}}
              <span v-if="view_receipt_paid_date !== '-'">
                | {{$t('statistics.detail_table_Payout login')}}: {{view_receipt_paid_login}}
                | {{$t('statistics.detail_table_Payout date')}}: {{view_receipt_paid_date}}
              </span>
              <span v-if="view_receipt_reject_date !== '-'">
                | {{$t('statistics.detail_table_Reject login')}}: {{view_receipt_reject_login}}
                | {{$t('statistics.detail_table_Reject date')}}: {{view_receipt_reject_date}}
              </span>
            </span>
            <div class="bets_table_cont">
              <table class="bets_table">
                <thead>
                <tr>
                  <th>{{ $t('cashier.event') }}</th>
                  <th>{{ $t('cashier.bet') }}</th>
                  <th>{{ $t('cashier.betvalue') }}</th>
                  <th>{{ $t('cashier.betstatus') }}</th>
                  <th>{{ $t('cashier.payout') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="bet in view_receipt_bets" :key="bet.bet">
                  <td>{{bet.public_id}}</td>
                  <td>{{tBetName(bet.bet_name, bet.bet_need_translate)}}{{bet.bet_value_postfix}}</td>
                  <td>{{bet.bet_sum}}</td>
                  <td>{{$t('cashier.bet_status_'+bet.status)}}</td>
                  <td>{{bet.payout}}</td>
                </tr>
                <tr v-if="view_receipt_status === 'not_paid'">
                  <td colspan="5"><h2>{{$t('cashier.totalpayout')}} {{view_receipt_bets[0].total_payout}}</h2></td>
                </tr>
                </tbody>
              </table>

            </div>
            <div id="view_receipt_buttons">
              <button class="black_button receipt_buttons" @click="close_receipt_view">{{ $t('cashier.close_receipt') }}</button>
              <button class="black_button receipt_buttons" @click="repeat_receipt(view_receipt_id)">{{ $t('cashier.repeat_receipt') }}</button>
              <button class="black_button receipt_buttons" v-if="view_receipt_status === 'not_played' && view_receipt_cancel === 'y' && view_receipt_bets[0].receipt_rejects_left > 0" @click="check_protected('cancel',view_receipt_id, false)">{{ $t('cashier.cancelreceipt') }}</button>
              <button class="black_button receipt_buttons" v-if="view_receipt_status === 'not_paid' && (view_receipt_all_can_payout === 'y' || view_receipt_owner === 'self')" @click="check_protected('payout', view_receipt_id, false)">{{ $t('cashier.payout_receipt') }}</button>
              <button class="black_button receipt_buttons" v-if="view_receipt_status === 'not_paid' && (view_receipt_all_can_payout === 'y' || view_receipt_owner === 'self')" @click="check_protected('payout', view_receipt_id, true)">{{ $t('cashier.payoutprint') }}</button>
              <button class="black_button receipt_buttons" @click="getReceiptPrint(view_receipt_id, 'copy')">{{ $t('cashier.printcopy') }}</button>
            </div>
          </div>
        </div>
      </div>
      <div id="cashier_bets">
        <span class="minmax"><span :class="blink_class">{{ $t('cashier.min') }} {{bet_buttons.min_bet}}</span> {{$t('cashier.max')}} {{bet_buttons.max_bet}}</span>
        <div id="cashier_bets_value">
          <span :class="blink_class">{{$t('cashier.splitSum')}}</span>
          <Toggle
              v-model="splitActive"
              class="toggle-blue"
              @change="toggleSplit"
          ></Toggle>
          <input v-model="bet_value" @keypress="isNumber($event)" :disabled="!manualBetPerm()">
          <button class="black_button" @click="clearBetValue()">X</button>
        </div>
        <div id="cashier_bets_buttons">
          <button @click="addBetValue(bet_buttons.b1)" class="black_button" :disabled="bet_buttons.b1_act === 'f'">{{bet_buttons.b1}}</button>
          <button @click="addBetValue(bet_buttons.b2)" class="black_button" :disabled="bet_buttons.b2_act === 'f'">{{bet_buttons.b2}}</button>
          <button @click="addBetValue(bet_buttons.b3)" class="black_button" :disabled="bet_buttons.b3_act === 'f'">{{bet_buttons.b3}}</button>
          <button @click="addBetValue(bet_buttons.b4)" class="black_button" :disabled="bet_buttons.b4_act === 'f'">{{bet_buttons.b4}}</button>
          <button @click="addBetValue(bet_buttons.b5)" class="black_button" :disabled="bet_buttons.b5_act === 'f'">{{bet_buttons.b5}}</button>
          <button @click="addBetValue(bet_buttons.b6)" class="black_button" :disabled="bet_buttons.b6_act === 'f'">{{bet_buttons.b6}}</button>
        </div>
        <div class="bet_control">
          <button @click="applyBet" class="apply black_button" :disabled="checkBetCanApply()">{{ $t('cashier.applybet') }}<br><span class="bet_amount_hint">{{bet_amount_hint}}</span></button>
          <button @click="clearBets()" :disabled="bet_list.length === 0" class="clear_bets black_button"><font-awesome-icon icon="trash-alt" /></button>
        </div>
        <div id="applyed_bets">
          <div class="applyed_bet" v-for="bet in bet_list" :key="bet.game+bet.bet">
            <div class="applyed_bet_top">
              <span>{{$t('cashier.round')}}{{bet.public_event}}</span>
              <button class="del_bet" @click="delete_bet(bet)">X</button>
            </div>
            <span class="abi" :class="{small_text: selected_game === 'Keno'}">{{tBetName(bet.bet_name, bet.ntt)}}</span>
            <span v-if="bet.coef_type === undefined || bet.coef_type == 'decimal'" class="abi">{{bet.bet_value}} {{this.bet_buttons.symbol}} x {{bet.multiplier}}</span>
            <span v-if="bet.coef_type !== undefined && bet.coef_type == 'american'" class="abi">{{bet.bet_value}} {{this.bet_buttons.symbol}} ({{convert_coef(bet.multiplier, bet.coef_type)}})</span>
          </div>
        </div>
        <div class="bets_summary" :class="{backgorunded: bets_summary !== ''}">
          <span>{{bets_summary}}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import cashier from '../services/cashier.service';
import receiptService from '../services/receipt.service';
import GameTemplate from './GameTemplate';
import {inject, provide} from "vue";
import '@vueform/toggle/themes/default.css'
import Toggle from '@vueform/toggle'
export default {
  name: "Cashier",
  props: ['selectedGameType','selectedGameId', 'selectedGameName'],
  setup()
  {
    const setBalance = inject('setBalance');
    const selected_game = inject('gameType');
    const selected_game_id = inject('gameId');
    const selected_game_name = inject('gameName');
    const isUpBalance = inject('isUpBalance');
    const setIsUpBalance = inject('setIsUpBalance');
    const set_result = inject('set_result');
    const route = inject('route');
    const bet_buttons = inject('bet_buttons');
    const bet_value = inject('bet_value');
    const updateBalance = inject('updateBalance');
    const currency_symbol = inject('currency_symbol');
    const currency_iso = inject('currency_iso');
    const view_receipt_bets = inject('view_receipt_bets');
    const setViewReceiptBets = inject('setViewReceiptBets');
    const currentUser = inject('currentUser');

    const convert_coef = (coef, type) =>
    {
      if (type == 'american')
      {
        if (coef <= 1)
        {
          return 0;
        }

        if (coef >= 2)
        {
          return '+'+Math.floor(((coef - 1) * 100));
        }

        return  Math.ceil((-100 / (coef - 1)));
      }

      return coef;
    }

    provide('convert_coef', convert_coef);

    return {
      setBalance,
      selected_game,
      selected_game_id,
      selected_game_name,
      isUpBalance,
      setIsUpBalance,
      set_result,
      route,
      bet_buttons,
      bet_value,
      updateBalance,
      currency_symbol,
      currency_iso,
      view_receipt_bets,
      setViewReceiptBets,
      currentUser,
      convert_coef
    };
  },
  data () {
    return {
      games: [],
      current_bet: '',
      bet_list: [],
      receipt_list: [],
      current_event: '',
      public_event: '',
      btp_full_screen: false,
      btp_exp_title: 'expand',
      view_receipt_id: '',
      view_receipt_status: '',
      view_receipt_protected: '',
      view_receipt_cancel: '',
      view_receipt_all_can_payout: '',
      view_receipt_owner: '',
      view_receipt_date: '',
      view_receipt_paid_date: '-',
      view_receipt_reject_date: '-',
      view_receipt_login: '',
      view_receipt_paid_login: '-',
      view_receipt_reject_login: '-',
      balance: 0,
      not_played_val: true,
      not_paid_val: true,
      find_receipt: '',
      find_receipt_id: '',
      find_receipt_sc: '',
      finded_receipts: [],
      receipt_html: '',
      modal: '',
      secure_code: '',
      secure_codes_arr: [],
      print_state: false,
      receipt_action: '',
      enterDownCount: 0,
      bet_amount_hint: '',
      splitActive: false,
      blink_class: ''
    }
  },
  components: {
    GameTemplate,
    Toggle
  },
  methods: {
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;

      let disablePoint = true;

      if
      (
          this.bet_buttons.b1.includes('.') ||
          this.bet_buttons.b2.includes('.') ||
          this.bet_buttons.b3.includes('.') ||
          this.bet_buttons.b4.includes('.') ||
          this.bet_buttons.b5.includes('.') ||
          this.bet_buttons.b6.includes('.')
      )
      {
        disablePoint = false;
      }

      let cur_value = this.bet_value;

      if (((charCode > 31 && (charCode < 48 || charCode > 57)) && (charCode !== 46 && !disablePoint || disablePoint)) || this.countDecimals(cur_value) === 2) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    countDecimals(value)
    {
      if(Math.floor(value) == value) return 0;
      return value.toString().split(".")[1].length || 0;
    },
    addBetValue(value_incr)
    {
      if(this.bet_value*1+value_incr*1 > this.bet_buttons.max_bet)
      {
        this.bet_value = this.bet_buttons.max_bet;
      }
      else
      {
        this.bet_value = this.bet_value * 1 + value_incr * 1;
        this.bet_value = this.roundNumber(this.bet_value, 2 );
      }
    },
    roundNumber(number, decimals) {
      let new_number = new Number(number+'').toFixed(parseInt(decimals));
      return parseFloat(new_number);
    },
    clearBetValue()
    {
      this.bet_value = 0;
    },
    manualBetPerm()
    {
      if(this.currentUser.bill_groups !== undefined && this.getKeyByValue(this.currentUser.bill_groups, 'bill_enable_manual_bet_sum') !== undefined)
      {
        return true;
      }

      return false;
    },
    setCurrentBet(bet)
    {
      this.bet_list = bet;
      if(this.splitActive)
      {
        this.splitBets()
      }
    },
    applyBet()
    {
      this.bet_value = this.bet_buttons.b1;
      this.current_bet = '';
      this.$refs.game_template.clear_bet();
      this.makeReceipt(this.bet_list);
      this.bet_list = [];
    },
    getKeyByValue(object, value) {
      return Object.keys(object).find(key =>
          object[key] === value);
    } ,
    checkBetCanApply()
    {
      let ret = false;

      if(this.bet_list.length === 0)
      {
        ret = true;
      }

      if(this.bet_value*1 < this.bet_buttons.min_bet*1)
      {
        this.bet_amount_hint = 'less min'
        ret = true;
      }

      if(this.bet_value*1 > this.bet_buttons.max_bet*1)
      {
        this.bet_amount_hint = 'above max'
        ret = true;
      }

      if(this.bet_value*1 >= this.bet_buttons.min_bet*1 && this.bet_value*1 <= this.bet_buttons.max_bet*1)
      {
        this.bet_amount_hint = ''
      }

      return ret;
    },
    setCurrentEvent(event)
    {
      this.current_event = event.id;
      this.public_event = event.public_id;
    },
    expand_btp()
    {
      this.btp_full_screen = !this.btp_full_screen;

      if(this.btp_full_screen)
      {
        this.btp_exp_title = 'collapse';
      }
      else
      {
        this.btp_exp_title = 'expand';
      }
    },
    delete_bet(bet)
    {
      this.bet_list.splice(this.bet_list.indexOf(bet),1);
      if(this.splitActive)
      {
        this.splitBets()
      }
    },
    makeReceipt(bets)
    {
      let bill = this.bet_buttons.bill;
      let game = this.selected_game_id;
      cashier.makeReceipt(bill, game, bets).then(
          (response) => {
            if(!response.data.result)
            {
              alert(this.$t('cashier.error.Cannot make receipt:')+this.$t('cashier.error.'+response.data.message));
            }
            else
            {
              if(response.data.message === response.data.message.replace('np',''))
              {
                this.getReceiptPrint(response.data.message, 'new');
              }
              else
              {
                this.getReceiptPrint(response.data.message.replace('np',''), 'make');
                this.receipt_action = 'make';
                this.open_modal();
              }
            }

            this.updateBalance();
            this.setIsUpBalance();
            this.$refs.game_template.getEvents(game);
          },
          (error) => {
            this.set_result(error.response.status)
          }
      );
    },
    repeat_receipt(id)
    {
      let game = this.selected_game_id;

      cashier.repeatReceipt(id).then(
          (response) => {
            if(!response.data.result)
            {
              alert(this.$t('cashier.error.Cannot make receipt:')+this.$t('cashier.error.'+response.data.message));
            }
            else
            {
              if(response.data.message === response.data.message.replace('np',''))
              {
                this.getReceiptPrint(response.data.message, 'new');
              }
              else
              {
                this.getReceiptPrint(response.data.message.replace('np',''), 'make');
                this.receipt_action = 'make';
                this.open_modal();
              }
            }

            this.updateBalance();
            this.setIsUpBalance();
            this.$refs.game_template.getEvents(game);
          },
          (error) => {
            this.set_result(error.response.status)
          }
      );

      this.close_receipt_view();
    },
    getActiveReceipts(game_id)
    {
      cashier.getActiveReceipts(game_id).then(
          (response) => {
            this.receipt_list = response.data;
          },
          (error) => {
            this.set_result(error.response.status)
          }
      );
    },
    view_receipt(receipt)
    {
      cashier.getReceiptBets(receipt.id).then(
          (response) => {
            this.setViewReceiptBets(response.data);
            this.view_receipt_id = receipt.id;
            this.view_receipt_status = receipt.status;
            this.view_receipt_protected = receipt.is_protected;
            this.view_receipt_cancel = receipt.can_cancel;
            this.view_receipt_owner = receipt.receipt_owner;
            this.view_receipt_all_can_payout = receipt.all_can_payout;
            this.view_receipt_date = receipt.check_data_tz;
            this.view_receipt_paid_date = receipt.payout_date_tz||'-';
            this.view_receipt_reject_date = receipt.reject_date_tz||'-';
            this.view_receipt_login = receipt.cashier_accept;
            this.view_receipt_paid_login = receipt.cashier_paid||'-';
            this.view_receipt_reject_login = receipt.cashier_reject||'-';
            this.enterDownCount = 0;

            for (let row in response.data)
            {
              if(response.data[row].status === 'paid')
              {
                this.view_receipt_status = 'paid'
              }
            }
          },
          (error) => {
            this.set_result(error.response.status);
            this.enterDownCount = 0;
          }
      );

      this.expand_btp();
    },
    close_receipt_view()
    {
      this.setViewReceiptBets([]);
      this.view_receipt_id = '';
      this.secure_code = '';
      this.expand_btp();
      this.getActiveReceipts(this.selected_game_id);
    },

    cancel_receipt(id)
    {
      cashier.cancelReceipt(id, this.secure_code).then(
          (response) => {
            if(!response.data.result)
            {
              alert(this.$t('cashier.error.Cannot cancel receipt:')+this.$t('cashier.error.'+response.data.message));
            }
            else
            {
              this.expand_btp();
              this.close_modal();
              this.updateBalance();
              this.setIsUpBalance();
              this.setViewReceiptBets([]);
              this.$refs.game_template.getEvents(this.selected_game_id);
            }
          },
          (error) => {
            this.set_result(error.response.status)
          }
      );
    },
    find_receipts()
    {
      this.finded_receipts = [];
      let find_length = this.find_receipt_id.toString().length;

      if(find_length > 1)
      {
        for (let receipt of this.receipt_list)
        {
          if (receipt.id.substr(0,find_length) == this.find_receipt_id)
          {
            this.finded_receipts.push(receipt);
          }
        }
      }
    },
    countdownEnd()
    {
      this.getActiveReceipts(this.selected_game_id);
    },
    check_protected(action, id, print)
    {
      this.print_state = print;

      if(this.view_receipt_protected === 'y')
      {
        this.open_modal();
        this.receipt_action = action;
      }
      else
      {
        if(action === 'payout')
        {
          this.payout_receipt(this.view_receipt_id, this.print_state);
        }

        if(action === 'cancel')
        {
          this.cancel_receipt(this.view_receipt_id);
        }
      }
    },
    verify()
    {
      if(this.receipt_action === 'payout')
      {
        this.payout_receipt(this.view_receipt_id, this.print_state);
      }

      if(this.receipt_action === 'cancel')
      {
        this.cancel_receipt(this.view_receipt_id);
      }
    },
    payout_receipt(id, print)
    {
      cashier.payoutReceipt(id, this.secure_code).then(
          (response) => {
            if(!response.data.result)
            {
              alert(this.$t('cashier.error.Cannot payout receipt:')+this.$t('cashier.error.'+response.data.message));
            }
            else
            {
              this.close_modal();
              this.expand_btp();

              if(print)
              {
                this.getReceiptPrint(id, 'payout');
              }

              this.updateBalance();
              this.setIsUpBalance();
              this.setViewReceiptBets([]);
              this.$refs.game_template.getEvents(this.selected_game_id);
            }
          },
          (error) => {
            this.set_result(error.response.status)
          }
      );
    },
    ProceedEntry()
    {
      let curFS = this.find_receipt;
      let finFS = '';
      if(!isNaN(parseInt(curFS)))
      {
        this.find_receipt_id = parseInt(curFS);
      }

      finFS = this.find_receipt_id;

      let csPrep = curFS.split('-');

      if(csPrep[1] !== undefined)
      {
        finFS = finFS.toString()+'-'+csPrep[1];
      }
      else
      {
        finFS = curFS;
      }

      if(curFS.length >= 11)
      {
        if(csPrep[1] !== undefined && csPrep[1].length === 6)
        {
          this.secure_codes_arr[this.find_receipt_id] = csPrep[1];
        }
      }

      finFS = finFS.replace(/^0+/g, '');
      this.find_receipt = finFS;
    },
    tryOpenReceipt()
    {
      if(this.enterDownCount === 0)
      {
        this.enterDownCount++;

        if(this.finded_receipts.length === 1)
        {
          this.view_receipt(this.finded_receipts[0]);
        }
      }
    },
    clearBets()
    {
      this.bet_list = [];
      this.$refs.game_template.clear_bet();
    },
    getReceiptPrint (id, type) {
      receiptService.getReceiptPrint(id, type).then(
          (response) => {
            if(type !== 'make')
            {
              this.printDiv(response.data);
            }
            else
            {
              this.receipt_html = response.data;
            }
          },
          (error) => {
            this.set_result(error.response.status)
          })
    },
    printDiv(html) {
      let frame1 = document.createElement('iframe');
      frame1.name = "frame1";
      frame1.style.position = "absolute";
      frame1.style.top = "-1000000px";
      document.body.appendChild(frame1);
      let frameDoc = frame1.contentWindow ? frame1.contentWindow : frame1.contentDocument.document ? frame1.contentDocument.document : frame1.contentDocument;
      frameDoc.document.open();
      frameDoc.document.write('<html lang="en">');
      frameDoc.document.write('<head>');
      frameDoc.document.write('<style>');
      frameDoc.document.write(cashier.getQuillCss());
      frameDoc.document.write('</style>');
      frameDoc.document.write('</head>');
      frameDoc.document.write('<body>');
      frameDoc.document.write(html);
      frameDoc.document.write('</body>');
      frameDoc.document.write('</html>');
      frameDoc.document.close();
      setTimeout(function () {
        window.frames["frame1"].focus();
        window.frames["frame1"].print();
        document.body.removeChild(frame1);
      }, 500);
    },
    closeModalEvent(event)
    {
      if (event.keyCode == 13 && this.modal === 'open') {
        this.close_modal();
      }
    },
    open_modal()
    {
      this.modal = 'open';

      if(this.receipt_action === 'make')
      {
        window.addEventListener('keyup', this.closeModalEvent);
      }

      if(this.secure_codes_arr[this.find_receipt_id] !== undefined)
      {
        this.secure_code = this.secure_codes_arr[this.find_receipt_id];
      }
    },
    close_modal()
    {
      this.modal = '';
      this.print_state = false;
      this.receipt_action = '';
      this.secure_code = '';
      window.removeEventListener('keyup', this.closeModalEvent, false);
    },
    check_modal_rec_class()
    {
      if(this.receipt_action === 'make')
      {
        return true;
      }

      return false;
    },
    check_open_class()
    {
      if(this.modal === 'open')
      {
        return true;
      }

      return false;
    },
    check_modal_class()
    {
      if(this.receipt_action !== 'make')
      {
        return true;
      }

      return false;
    },
    view_receipt_tr(status)
    {
      if(status === 'not_paid')
      {
        return 'not_paid_tr';
      }
      if(status === 'pending_confirmation')
      {
        return 'pending_confirmation_tr';
      }
    },
    tBetName(name, ntt)
    {
      if(ntt === 'n')
      {
        return name;
      }

      if(name.includes('split_'))
      {
        let rname = name;
        rname = this.$t('cashier.betName.split')+rname.replace('split','');
        return rname;
      }

      if(name.includes('corner_'))
      {
        let rname = name;
        rname = this.$t('cashier.betName.corner')+rname.replace('corner','');
        return rname;
      }

      if(name.includes('six-line_'))
      {
        let rname = name;
        rname = this.$t('cashier.betName.six-line')+rname.replace('six-line','');
        return rname;
      }

      if(name.includes('line_'))
      {
        let rname = name;
        rname = this.$t('cashier.betName.line')+rname.replace('line','');
        return rname;
      }
      
      if(this.$t('cashier.betName.'+name) !== 'cashier.betName.'+name)
      {
        return this.$t('cashier.betName.'+name);
      }
      return name;
    },
    toggleSplit() {

      let bet_amount = this.bet_value*1
      let bets_cnt = this.bet_list.length
      let split_amount = bet_amount/bets_cnt
      let min_bet = this.bet_buttons.min_bet*1

      if(split_amount < min_bet)
      {
        this.splitActive = false
        this.blink_ms(1000)
        return
      }

      if(this.splitActive)
      {
        this.splitBets()
      }
    },
    splitBets()
    {
      let bets_cnt = this.bet_list.length
      let min_bet = this.bet_buttons.min_bet*1
      let min_bet_parts = min_bet.toString().split('.')
      let precision = 0
      let bet_amount = this.bet_value*1
      let split_amount = bet_amount
      let cur_sum = 0

      if(min_bet_parts[1] !== undefined)
      {
        precision = min_bet_parts[1].length
      }

      if(bets_cnt < 2)
      {
        return
      }

      split_amount = bet_amount/bets_cnt

      if(split_amount < min_bet)
      {
        this.blink_ms(1000)
        this.bet_list.pop()
        return
      }

      for(let i = 0; i<=bets_cnt-1; i++)
      {
        let loopAmount = split_amount
        let loopAmountMod = loopAmount % min_bet

        if(loopAmountMod > 0)
        {
          if(loopAmountMod.toFixed(precision+1)*1 !== min_bet)
          {
            loopAmount = loopAmount-loopAmountMod
          }
        }

        if(i !== bets_cnt-1)
        {
          cur_sum = cur_sum.toFixed(precision)*1+loopAmount.toFixed(precision)*1
        }

        let bet_value = loopAmount

        this.bet_list[i].bet_value = bet_value.toFixed(precision)*1
        if(i === bets_cnt-1)
        {
          bet_value = bet_amount- cur_sum.toFixed(precision)*1
          this.bet_list[i].bet_value = bet_value.toFixed(precision)*1
        }
      }
    },
    blink_ms(ms)
    {
      this.blink_class = 'blink'
      setTimeout(() => {this.blink_class = ''}, ms);
    }
  },
  mounted() {
    this.getActiveReceipts(this.selected_game_id);
  },
  computed: {
    receipts_comp() {
      let receipts_comp = [];
      let not_paid = this.not_paid_val;
      let not_played = this.not_played_val;

      if(this.receipt_list.length > 0)
      {
        for(let receipt of this.receipt_list)
        {
          if(not_paid === true && (receipt.status === 'not_paid' || receipt.status === 'pending_confirmation'))
          {
            receipts_comp.push(receipt);
          }

          if(not_played === true && (receipt.status === 'not_played' || receipt.status === 'playing'))
          {
            receipts_comp.push(receipt);
          }
        }
      }
      
      if(this.find_receipt.toString().length > 1)
      {
        receipts_comp = this.finded_receipts;
      }

      return receipts_comp;
    },
    bets_summary()
    {
      let ret = '';
      let sum = 0;
      for(let bet of this.bet_list)
      {
        sum = sum+bet.bet_value*1;
      }

      if(this.bet_list.length > 0)
      {
        ret = this.bet_list.length+' '+this.$t('cashier.bets')+': '+sum.toFixed(2)+' '+this.currency_iso;
      }

      return ret;
    }
  },
  watch: {
    isUpBalance: function() {
      if(this.route.name !== 'statistics')
      {
        this.getActiveReceipts(this.selected_game_id);
      }
    }
  }
}
</script>

<style scoped>
  #bets_preview
  {
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 100%;
    height: 100%;
  }

  #receipts
  {
    display: grid;
    grid-template-rows: 20% 80%;
    height: 100%;
  }
  .receipt_table_data
  {
    height: 100%;
    overflow-y: scroll;
  }
  .receipt_table_header
  {
    height: 100%;
  }
  .full_height
  {
    padding-left: 20px;
    left: 0px;
    position: absolute;
    z-index: 10;
    height: 95% !important;
    width: 100%;
    background-color: white;
  }
  #cashier_container
  {
    width: 100%;
    height: 100%;
  }
  #cashier
  {
    height: 100%;
    display: grid;
    grid-template-columns: 85% 15%;
    grid-template-rows: 100%;
  }
  #cashier_game
  {
    margin-left: 1%;
    height: 100%;
    display: grid;
    grid-template-rows: 60% 40%;
    grid-template-columns: 100%;
  }
  #cashier_bets
  {
    border-left: 1px solid;
    display: grid;
    grid-template-rows: 5% 10% 13% 7% 60% 5%;
    grid-template-columns: 100%;
    padding: 3%;
    height: 100%;
  }
  #game_template
  {
    height: 100%;
    display: grid;
    grid-template-rows: 19% 81%;
  }
  .apply
  {
    margin: 2%;
    background-image: linear-gradient(chartreuse, forestgreen) !important;
  }
  .apply:disabled
  {
    background-image: linear-gradient(darkgray, gray) !important;
  }
  .clear_bets
  {
    margin: 3.5%;
    background-image: linear-gradient(#007bff, #007bff) !important;
  }
  .clear_bets:disabled
  {
    background-image: linear-gradient(darkgray, gray) !important;
  }
  .black_button
  {
    align-items: center;
    background-color: initial;
    background-image: linear-gradient(#464d55, #25292e);
    border-radius: 8px;
    border-width: 0;
    box-shadow: 0 10px 20px rgba(0, 0, 0, .1),0 3px 6px rgba(0, 0, 0, .05);
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    font-family: expo-brand-demi,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-size: 18px;
    height: 90%;
    justify-content: center;
    line-height: 1;
    outline: none;
    overflow: hidden;
    padding: 0 5px;
    text-align: center;
    text-decoration: none;
    transform: translate3d(0, 0, 0);
    transition: all 150ms;
    vertical-align: baseline;
    white-space: nowrap;
  }
  .receipt_buttons
  {
    margin-top: 20px;
  }
  .btp_hint
  {
    display:table;
    margin:0 auto;
    font-size: larger;
    font-weight: bold;
  }
  .bets_table_cont {
    overflow-y: auto;
    width: 100%;
  }
  #cashier_bets_buttons
  {
    display: grid;
    grid-template-columns: 33% 33% 33%;
  }
  #view_receipt_buttons
  {
    left: 0px;
    width: 100%;
  }
  #view_receipt_buttons button
  {
    padding: 5px;
  }
  #cashier_bets_buttons button
  {
    margin: 5%;
    width: 90%;
  }
  #cashier_bets_value
  {
    display: grid;
    grid-template-columns: 69% 28%;
    grid-template-rows: 35% 65%;
  }
  #cashier_bets_value button
  {
    width: 100%;
  }
  #cashier_bets input
  {
    margin-right: 7%;
    margin-left: 3%;
  }
  .receipt_table
  {
    width: 100%;
    border: 1px solid #dddddd;
    border-collapse: collapse;
    table-layout: fixed;
  }
  .receipt_table_th
  {
    font-size: 60%;
    width: 100%;
    height: 100%;
    table-layout: fixed;
  }
  .receipt_table_th th
  {
    background: #efefef;
    border: 1px solid #dddddd;
    height: 50%;
  }
  .receipt_table_th input
  {
    height: 75%;
  }
  .bets_table {
    width: 100%;
    border: 1px solid #dddddd;
    border-collapse: collapse;
  }
  .bets_table th, .receipt_table th {
    font-weight: bold;
    background: #efefef;
    border: 1px solid #dddddd;
  }
  .bets_table td, .receipt_table td {
    border: 1px solid #dddddd;
  }
  .receipt_table tr:hover
  {
    background-color: #CCCCCC;
    cursor: pointer;
  }
  .applyed_bet
  {
    display: grid;
    grid-template-rows: 33% 33% 33%;
    border: 1px solid;
    background-color: #CCCCCC;
    margin-top: 2%;
    padding: 1%;
  }
  .applyed_bet_top
  {
    flex-direction: row;
    display: flex;
    text-align: left;
    justify-content: space-between;
  }
  .abi
  {
    flex-direction: row;
    display: flex;
    text-align: left;
  }
  .small_text
  {
    font-size: 77%;
    font-weight: bold;
  }
  .del_bet
  {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #applyed_bets
  {
    overflow:auto;
    display: flow;
    text-align: center;
  }
  .ar_filter
  {
    margin-left: 10%;
  }
  .pseudo_scroll
  {
    width: 16px;
  }
  .minmax
  {
    display: flow;
    text-align: center;
  }
  .modal
  {
    background-color: #CCCCCC;
    height: 100px;
    width: 200px;
    top: 45%;
    left: 43%;
    border: 3px solid;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    text-align: center;
    vertical-align: baseline;
  }
  .bets_summary
  {
    justify-content: center;
    align-items: center;
    text-align: center;
    vertical-align: center;
    padding-top: 5%;
    margin-top: 1%;
  }
  .backgorunded
  {
    background-color: #CCCCCC;
  }
  .modal_receipt
  {
    background-color: #CCCCCC;
    height: 95%;
    width: 400px;
    padding: 15px 100px;
    top: 1%;
    left: 3%;
    border: 3px solid;
    border-radius: 10px;
    vertical-align: baseline;
    z-index: 1050;
    position: fixed;
  }
  .overlay
  {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
    background-color: #464d55;
  }
  .open
  {
    display: block !important;
  }
  .bcm
  {
    position: absolute;
    bottom: 10px;
    left: 45%;
  }
  .dvc
  {
    height: 85%;
    left: 13%;
    position: absolute;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: white;
  }
  .not_paid_tr
  {
    color: limegreen;
    font-weight: bold;
  }
  .pending_confirmation_tr
  {
    color: #ff4033;
    font-weight: bold;
  }
  .bet_amount_hint
  {
    color: red;
  }
  .bet_control
  {
    display: grid;
    grid-template-columns: 70% 30%;
  }
  .toggle-blue {
    --toggle-bg-on: #1e81b0;
    --toggle-bg-off: #8c918d;
    --toggle-border-on: #1e81b0;
    --toggle-border-off: #8c918d;
  }
  .toggle-container:focus {
    outline: none !important;
  }
  .blink {
    animation-name: blinker;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(1.0,0,0,1.0);
    animation-duration: 0.5s;
    -webkit-animation-name: blinker;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: cubic-bezier(1.0,0,0,1.0);
    -webkit-animation-duration: 0.5s;
    color: #ff212e;
  }

  @keyframes blinker {
    from { opacity: 1.0; }
    to { opacity: 0.0; }
  }

  @-webkit-keyframes blinker {
    from { opacity: 1.0; }
    to { opacity: 0.0; }
  }
  @import "~quill/dist/quill.core.css";
</style>